<template>
  <v-btn class=""  color="blue" v-if="sideA == 'car-plate-text'" @click="showSamples = !showSamples ">
    הצג משפטים לדוגמא
  </v-btn>

  <van-popup
      teleport="body"
      v-model:show="showSamples"
      class="material-select-modal"
      id="materialSelectModal"
      :position="width < 800 ? 'left':'center'"
      :style="{'width' : width < 800 ? '80%' : '50%'}"
      closeable
      z-index="99999"
  >
    <div class="modal-content py-5">
      <div class="modal-body px-2">
        <div class="title-h4">משפטים לדוגמא:</div>
        <div class="my-2 text-lg" v-for="text in product?.productFields?.sampleWords">
          {{ text?.text }}
        </div>
      </div>
    </div>
  </van-popup>


  <div class="keychain-wrapper">
    <div class="keychain-side">
      <div class="text-md my-2">בחר סוג התבנית:</div>
      <VSelect
          v-model="sideA"
          label="תבנית"
          placeholder="בחר סוג:"
          :items="product.productFields?.keychainProduct?.variations"
          item-title="name"
          item-value="nameEn"
      />
      <VTextField
          v-model="formattedSideAText"
          placeholder="הכנס טקסט"
          :maxlength="findLimit(sideA) + 2"
          :key="sideA"
          @input="onInputEmit"
          v-if="sideA"/>
      <div class="selected-keychain-image relative" :class="sideA" id="sideAContainer" :style="{'backgroundImage' : 'url(' + product.productFields?.keychainProduct?.variations?.find((item) => item.nameEn === sideA)?.select?.node?.sourceUrl + ')'}">
        <div class="side-text " id="sideAText" :key="sideA">{{formattedSideAText}}</div>
      </div>


    </div>
    <div class="keychain-side" v-if=" enableSideB">
      <div class="text-md my-2">בחר סוג התבנית:</div>
      <VSelect
          v-model="sideB"
          label="תבנית"
          placeholder="בחר סוג התבנית:"
          :items="product.productFields?.keychainProduct?.variations"
          item-title="name"
          item-value="nameEn"
      />
      <VTextField
          v-model="formattedSideBText"
          placeholder="הכנס טקסט"
          :maxlength="findLimit(sideB) + 2"
          :key="sideB"
          @input="onInputEmit"
          v-if="sideB"/>
      <div class="selected-keychain-image relative" :class="sideB" id="sideBContainer" :style="{'backgroundImage' : 'url(' + product.productFields?.keychainProduct?.variations?.find((item) => item.nameEn === sideB)?.select?.node?.sourceUrl + ')'}">
        <div class="side-text " id="sideBText" :key="sideB">{{formattedSideBText}}</div>
      </div>


    </div>
    <div class="row">

    <v-btn width="200" color="green" @click="enableSideB = !enableSideB" >
      הוספת צד שני
    </v-btn>
      <v-btn width="200" class="mx-3" color="blue" @click="cloneSideB" >
        שכפל לצד ב׳
      </v-btn>
    </div>
  </div>
</template>
<script setup lang="ts">

const props = defineProps(['product']);
console.log(props.product);
const { width, height } = useWindowSize();
const emits = defineEmits(['updateProduct']);
const showSamples = ref(false);
const enableSideB = ref(false);
const sideA = ref('');
const sideB = ref('');
const sideAText = ref('');
const sideBText = ref('');
const clonedSideB = ref(false);
const priceToAdd = ref(0);
watch(sideA , (newValue,oldValue) => {
  if (newValue !== oldValue){
    sideAText.value = '';
  }
});
watch(sideB , (newValue,oldValue) => {
  if (newValue !== oldValue){
    sideBText.value = '';
  }
});


const cloneSideB = () => {
  enableSideB.value = true;
  sideB.value = sideA.value;
  priceToAdd.value = props.product?.productFields?.keychainProduct.priceAdd;
  setTimeout(() => {
    sideBText.value = sideAText.value;
    emits('updateProduct', { type: sideA.value, text: formattedSideAText.value ,sideB:enableSideB.value, priceAdd:  priceToAdd.value , typeB: sideB.value, sideBText: formattedSideBText.value });
  }, 100);

};
const onInputEmit = () => {
  emits('updateProduct', { type: sideA.value, text: formattedSideAText.value ,sideB:enableSideB.value, priceAdd:priceToAdd.value , typeB: sideB.value, sideBText: formattedSideBText.value });
};


const formatText = (text) => {
  if (sideA.value === 'car-plate-8' && text && text.length === 8) {
    return `${text.slice(0, 3)}-${text.slice(3, 5)}-${text.slice(5)}`;
  } else if (sideA.value === 'car-plate-7' && text && text.length === 7) {
    return `${text.slice(0, 2)}-${text.slice(2, 5)}-${text.slice(5)}`;
  }
  return text;
};

const formattedSideAText = computed({
  get: () => formatText(sideAText.value),
  set: (value) => {
    sideAText.value = value.replace(/-/g, '');
  },
});
const formattedSideBText = computed({
  get: () => formatText(sideBText.value),
  set: (value) => {
    sideBText.value = value.replace(/-/g, '');
  },
});


const findLimit = (side:String) => {
  return props.product?.productFields?.keychainProduct?.variations?.find((item : Object) => item?.nameEn === side)?.limit;
}

</script>
<style scoped lang="scss">
.selected-keychain-image{

}
</style>